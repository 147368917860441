.input-label {
    font-size: 14px;
    color: #3f4254;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 8px;
}

.default-input {
    border: 1px solid #f0f0f0 !important;
    /* height: 40px; */
}

.default-input:focus {
    border: 1px solid rgb(213, 213, 213) !important;
}