.css-13cymwt-control,
.css-1fdsijx-ValueContainer {
  /* border-color: transparent !important;
    background-color: transparent !important;
    box-shadow: 0 0 0 0px transparent !important; */

  background-color: var(--bs-gray-100) !important;

  /* border-color: var(--bs-gray-100) !important; */
  color: var(--bs-gray-700) !important;
  padding: 0;
  font-size: 1.15rem;
  border-radius: 0.625rem;
  height: 30px;
}

.css-16xfy0z-control {
  background-color: var(--bs-gray-300) !important;
  border: 1px solid #f0f0f0 !important;
  cursor: not-allowed !important;
}

.css-16xfy0z-control .css-1fdsijx-ValueContainer {
  background-color: var(--bs-gray-300) !important;
  cursor: not-allowed !important;
  opacity: 1;

}

.css-olqui2-singleValue {
  color: var(--bs-gray-700) !important;
}

.css-13cymwt-control,
.react-dropdown-select,
.react-multi-select {
  border: 1px solid #f0f0f0 !important;
}

.css-13cymwt-control:focus,
.react-dropdown-select,
.react-multi-select {
  border: 1px solid rgb(213, 213, 213) !important;
}

.react-multi-select {
  border-radius: 0.625rem !important;
}

.css-t3ipsp-control,
.react-dropdown-select {
  box-shadow: 0 0 0 0px transparent !important;
  border-width: 0px !important;
  background-color: var(--bs-gray-100) !important;
  padding: 0.4rem 0.5rem !important;
  border-radius: 0.625rem !important;
}

.css-1nmdiq5-menu {
  margin-left: 1%;
  width: 98% !important;
  color: black !important;
  position: absolute !important;
}

.css-1dimb5e-singleValue {
  color: var(--bs-gray-700) !important;
  font-size: 1.15rem !important;

}

.table thead th,
.rdt_TableCol {
  font-weight: bold;
  text-transform: uppercase !important;
  color: #a1a5b7 !important;
}

.rdt_TableCell {
  padding-top: 10px;
  padding-bottom: 10px;
}

.hOjrSE,
.fjMIrw,
.fPIktX {
  color: var(--bs-table-color) !important;
  background-color: transparent !important;
}

.dILRuI,
.fjMIrw {
  background-color: transparent !important;
}

.inner-row {
  opacity: 0;
  animation: fade-in 0.3s ease-in forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-gray {
  color: gray;
}

select {
  text-transform: capitalize;
}

.remove-arrow.dropdown-toggle::after {
  display: none !important;
}

.table tr th:first-child {
  border-top-left-radius: 7px !important;
  border-bottom-left-radius: 7px !important;
}

.table tr th:last-child {
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
}

.table tr td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.table-row-dashed thead {
  position: sticky !important;
  top: 0 !important;
  background: white;
  z-index: 2 !important;
}

.table-height {
  height: 55vh !important;
  padding-right: 10px;
}

/* .table-arrow {
  display: flex;
  align-items: center;
  width: max-content;
  gap: 5px;
  cursor: pointer;
} */

.table {
  text-wrap: nowrap;
}

.username {
  color: var(--bs-text-gray-700);
}

@media (min-width: 992px) {
  body:not([data-kt-sticky-header=on]) .username {
    color: #fff;
  }
}

.ReactModal__Overlay {
  z-index: 2000 !important;
}

.remove-select-arrow {
  appearance: none !important;
}

/* .remaining-budget {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
} */

.css-1nmdiq5-menu {
  z-index: 120000 !important;
}

#react-select-4-listbox {
  z-index: 120000 !important;
}

.light-purple {
  background: #7239EA;
}

.purple {
  background: #4B25B7 !important;
}

.dark-green {
  background-color: #00945F !important;
}

.dark-danger {
  background-color: #f1416c !important;
}

.inner-row {
  cursor: pointer;
}

.timeline-steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap
}

.timeline-steps .timeline-step {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem
}

/* @media (min-width:768px) { */
.timeline-steps .timeline-step:not(:last-child):after {
  content: "";
  display: block;
  border-top: .25rem dotted #3b82f6;
  width: 3.46rem;
  position: absolute;
  left: 7.5rem;
  top: .3125rem
}

.timeline-steps .timeline-step:not(:first-child):before {
  content: "";
  display: block;
  border-top: .25rem dotted #3b82f6;
  width: 3.8125rem;
  position: absolute;
  right: 7.5rem;
  top: .3125rem
}

/* } */

.timeline-steps .timeline-content {
  width: 10rem;
  text-align: center
}

.timeline-steps .timeline-content .inner-circle {
  border-radius: 1.5rem;
  height: 1rem;
  width: 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #3b82f6
}

.timeline-steps .timeline-content .inner-circle:before {
  content: "";
  background-color: #3b82f6;
  display: inline-block;
  height: 3rem;
  width: 3rem;
  min-width: 3rem;
  border-radius: 6.25rem;
  opacity: .5
}

#react-select-3-listbox {
  position: relative;
}

.rmsc .dropdown-container {
  background: var(--bs-gray-100) !important;
  border-color: var(--bs-gray-100) !important;
  color: var(--bs-gray-700) !important;
}

.css-nfp1ut,
.css-z2hyoe-ItemComponent {
  color: #000 !important;
}

/* .apexcharts-legend {
  justify-content: center !important;
  width: max-content !important;
} */
.legend-center .apexcharts-legend {
  width: 100% !important;
  justify-content: center !important;
  width: max-content !important;
}

.approver-status .apexcharts-legend {
  width: 100% !important;
}

.css-t3ipsp-control {
  height: 30px !important;
  border: 1px solid #f0f0f0 !important;
}

.css-15lsz6c-indicatorContainer {
  color: hsl(0, 0%, 80%);
  padding: 4px !important;
}


.sm-scroll::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.table-responsive::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.sm-scroll::-webkit-scrollbar,
.table-responsive::-webkit-scrollbar {
  background-color: #caf3e8;
}

.sm-scroll,
.table-responsive {
  scrollbar-color: #009ef7 #caf3e8 !important;
  cursor: pointer;
}

.sm-scroll::-webkit-scrollbar-thumb,
.table-responsive::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #009ef7 !important;
}


.searchWrapper {
  background-color: var(--bs-gray-100);
  border-color: var(--bs-gray-100);
  min-height: calc(1.5em + 1.65rem + 2px);
  padding: 0.825rem 1.5rem;
}

.readMore,
.readLess {
  color: #009ef7;
}

.optionContainer .option {
  color: black;
}

.highlightOption {
  color: #fff !important;
}

.approver-report .apexcharts-legend {
  /* justify-content: center !important; */
  /* align-items: center; */
  /* width: 100% !important; */
  /* right: 65px !important; */
}

.approver-report .apexcharts-canvas {
  left: -70px !important;
}

.react-tooltip {
  z-index: 1200;
}

.custom-modal-size {
  max-width: 90%;
  width: 90%;
}

.pac-container {
  z-index: 1100 !important;
}

.modal {
  background: rgba(0, 0, 0, 0.4);
}

.dropdown-toggle::after {
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
}

.h-min-content {
  height: min-content;
}

.btn-xsm {
  height: 25px;
  padding: 0px I !important;
  font-size: 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  border-radius: 3px;
}

.bg-transparent {
  background: transparent !important;
}

.toggle-icon-remove::after {
  display: none;
}

.default-input {
  border: 1px solid #f0f0f0 !important;
  /* height: 40px; */
}

.default-input:focus {
  border: 1px solid rgb(213, 213, 213) !important;
}

.report-file-w-25 {
  width: 25%;
}

.css-1jqq78o-placeholder {
  text-wrap: nowrap;
  overflow-x: hidden;
}

@media (max-width: 558px) {
  .approver-report .apexcharts-canvas {
    left: 0px !important;
  }

  .report-file {
    flex-direction: column;
  }

  .report-file-w-25 {
    width: 100%;
  }

  .custom-modal-size {
    max-width: 97%;
    width: 97%;
  }
}

.react-dropdown-select-dropdown div {
  order: -1;
}

.dropdown-content,
.optionListContainer {
  z-index: 3 !important;
}

.css-h7uqf5-DropDown {
  color: #000;
}

.category-barchart .apexcharts-canvas svg {
  height: 450px !important;
}

svg:focus {
  outline: none;
}

/* .daterangepicker {
  top: 243.594px !important;
  left: auto !important;
  right: 0px !important;
} */

.drp-selected {
  color: #000 !important;
}

.w-fit-content {
  width: fit-content;
}

.w-max-content {
  width: max-content;
}

.dark-light-svg,
td span svg path {
  fill: var(--bs-table-color);
}

.btn-icon:hover .text-danger path,
td .btn-icon:hover span .svg-danger path {
  fill: var(--bs-danger) !important
}

.dark-light-svg,
.btn-icon:hover svg path,
td .btn-icon:hover span svg path {
  fill: var(--bs-primary) !important;
}

.hover-warning:hover svg path {
  fill: var(--bs-warning) !important;
}

.hover-white:hover svg path {
  fill: var(--bs-white) !important;

}

.btn-icon:hover .text-success path {
  fill: var(--bs-success) !important;
}

.hover-danger:hover svg path {
  fill: var(--bs-danger) !important;
}

.sticky-responsive {
  max-height: 300px;
  overflow-y: auto;
  padding-right: 7px;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #009ef7;
  border-radius: 5px;
}

.svg-gray svg path {
  fill: gray;
}

.svg-white svg path {
  fill: var(--bs-white) !important;
}

.svg-dark svg path {
  fill: #000 !important;
}

.filter-valueContainer .css-3w2yfm-ValueContainer {
  overflow: auto !important;
  height: 36px !important;
}

.css-1dyz3mf {
  overflow: auto !important;
  height: 33px !important;
}

.report-side-tabs .nav-link {
  height: 40px !important;
  display: flex;
  align-items: center;
}

.amount-positive {
  color: green;
}

.amount-negative {
  color: red;
}

.default-input:disabled,
.form-check-input:disabled {
  background-color: var(--bs-gray-300);
  /* cursor: not-allowed; */
  opacity: 1;
}

.css-1cfo1cf input {
  color: var(--bs-text-gray-700) !important;
}

.daterangepicker.show-ranges.show-calendar .ranges {
  background: #fff;
}

.daterangepicker .ranges ul {
  max-height: 360px;
}

.daterangepicker.show-calendar .ranges {
  height: 360px;
}