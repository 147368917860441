//
// Hover effects
//

.hover-elevate-up {
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-2.5%);
    transition: transform 0.3s ease;
  }
}

.hover-elevate-down {
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(2.5%);
    transition: transform 0.3s ease;
  }
}

.hover-scale {
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
}

.hover-rotate-end {
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(4deg);
    transition: transform 0.3s ease;
  }
}

.hover-rotate-start {
  transition: transform 0.3s ease;

  &:hover {
    transform: rotate(-4deg);
    transition: transform 0.3s ease;
  }
}
